.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus,
.ps__rail-x.ps--clicking,
.ps__rail-y.ps--clicking {
    background-color: transparent;
    opacity: 0.9;
}

.ps__rail-y:hover>.ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: #DAE2F3;
    width: 4px;
}

.ps__thumb-y {
    background-color: #DAE2F3;
    width: 4px;
}

.total-average {
    position: relative;
    height: 300px;
}

.widget-chat {
    position: relative;
    height: 250px;
}

.widget-todo {
    position: relative;
    height: 210px;
}

.widget-team {
    position: relative;
    height: 285px;
}

.widget-timeline {
    position: relative;
}

.widget-comments {
    position: relative;
    height: 400px;
}

.sidebar-right-inner {
    position: relative;
    height: 100%;
}

.widget-team {
    .ps .ps__rail-x:hover,
    .ps .ps__rail-y:hover,
    .ps .ps__rail-x:focus,
    .ps .ps__rail-y:focus,
    .ps .ps__rail-x.ps--clicking,
    .ps .ps__rail-y.ps--clicking {
        background-color: transparent!important;
        opacity: 0.9;
    }
}