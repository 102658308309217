.header-left {
    height: 100%;
    display: flex;
    align-items: center;
    input {
        background: var(--light)!important;
        min-width: 300px;
        min-height: 44px;
        border-color: transparent;
        box-shadow: none;
        height: 44px;
        padding: 0;
        padding-#{$dlab-pos-right}: 20px;
        @include respond(phone-land){
            padding: 20px;    
        }
        &:focus,
        &:active,
        &.active {
            border-color: transparent;
            box-shadow: none;
        }
        &::-webkit-input-placeholder {
            /* Edge */
            color: $dark;
        }
        &:-ms-input-placeholder {
            /* Internet Explorer */
            color: $dark;
        }
        &::placeholder {
            color: $dark;
        }
    }
}

.search_bar {
    display: flex;
    align-items: center;
    height: 100%;
    @include respond(phone){
        display: none;
    }
    .dropdown-menu {
        box-shadow: none;
    }
    input::placeholder{
        color: var(--text);
    }
    .search_icon {
        background: var(--light)!important;
        height: 44px;
        padding: 5px 15px!important;
        border-top-#{$dlab-pos-left}-radius: 5rem;
        border-bottom-#{$dlab-pos-left}-radius: 5rem;

        i {
            font-size: 24px;
            color: var(--text);
        }
        @include respond('phone-land') {
            border-radius: 4px;
            padding: 5px 10px !important;
        }
    }
    @include custommq($max: 767px) {
        position: static;
        .dropdown-menu {
            width: 300px;
            #{$dlab-pos-left} : -15vw;
            box-shadow: 0px 5px 10px 0 rgba(0,0,0,0.1);
            border-radius: 4px !important;
            @include respond('phone') {
                width: 250px;
                #{$dlab-pos-left} : -25vw;
            }	
            .form-control{
                border-radius: 4px !important;
                width:100%;
            }
        }
    }
    
    .dropdown-menu,
    .dropdown-menu.show {
        border: 0px;
        background-color: transparent;
        border-top-#{$dlab-pos-right}-radius: 5rem;
        border-bottom-#{$dlab-pos-right}-radius: 5rem;
        @include custommq($min: 768px) {
            #{$dlab-pos-left} : 90%;
            top: 50%;
            transform: translateY(-50%);
            display: block;
        }
    }
}

[data-sidebar-style="compact"] {
    .header-left {
        margin-#{$dlab-pos-left}: 0;
    }
}

.search_bar{
    .dropdown-menu[data-bs-popper]{
        #{$dlab-pos-right} :  unset !important
    }
}

@include respond('phone') {
    .search_bar{
        .dropdown-menu[data-bs-popper]{
            #{$dlab-pos-right} :  0 !important
        }
    }
}