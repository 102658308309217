// Basic Label
.form-label,.col-form-label{
	font-weight: 500;
    color: var(--text-dark);
	font-size: .875rem;
}


// Basic Input
.form-control{
    // border-radius: 0;
    background: var(--card);
	border-color: var(--border);
    // height: 45px;
    color: var(--text-dark);
	height: 2.7rem;
	padding:0.3125rem .95rem;
    &:hover,&:focus,&.active{
        box-shadow: none;
    }
	&::placeholder{
		color:var(--text);
	}
	&.solid{
		background-color: var(--light);
		border: 1px solid var(--light);
		&:hover,&:focus,&.active{
			background-color: var(--light);
		}
	}
	&.form-control-lg{
		line-height:2.5rem;
		height: 3.7rem;
		.dropdown-toggle{
			padding:0.5rem 1.4rem;
		}
		
	}
	&.form-control-sm{
		line-height: 1.7rem;
		height: 2.3rem;
		.dropdown-toggle{
			padding:0.25rem 1.4rem;
		}
		.dropdown-item{
			line-height: 0.8rem;
		}
	}
}
.form-control[type="file"]{
	line-height: 2rem;
    padding-#{$dlab-pos-left}: 0.6rem;
}
.form-control-sm[type="file"]{
    line-height: 1.75rem;
    padding: 3px;
    padding-#{$dlab-pos-left}: 6px;
}
.form-control-lg[type="file"]{
	line-height:2.675rem;
	padding-#{$dlab-pos-left}:10px;
}

.input-rounded {
    border-radius: 100px;
}


// Input Color
[data-theme-version="light"],
[data-theme-version="dark"] {
	.input-primary{
		.form-control{
			border-color:var(--primary);
		}	
		.input-group-text{
			background-color:var(--primary);
			border-color:var(--primary);
			color:$white;
		}
	}
	.input-danger{
		.form-control{
			border-color:$danger;
		}	
		.input-group-text{
			border-color:$danger;
			background-color:$danger;
			color:$white;
		}
	}
	.input-info{
		.form-control{
			border-color:$info;
		}	
		.input-group-text{
			background-color:$info;
			border-color:$info;
			color:$white;
		}
	}
	.input-success{
		.form-control{
			border-color:$success;
		}	
		.input-group-text{
			background-color:$success;
			border-color:$success;
			color:$white;
		}
	}
	.input-warning{
		.form-control{
			border-color:$warning;
		}	
		.input-group-text{
			background-color:$warning;
			color:$white;
		}
	}
}
// Input Outline
[data-theme-version="light"],
[data-theme-version="dark"] {
	.input-primary-o{
		.form-control{
			border-color:var(--primary);
			
		}	
		.input-group-text{
			background-color:transparent;
			border-color:var(--primary);
			color:var(--primary);
		}
	}
	.input-danger-o{
		.form-control{
			border-color:$danger;
		}	
		.input-group-text{
			background-color:transparent;
			border-color:$danger;
			color:$danger;
		}
	}
	.input-info-o{
		.form-control{
			border-color:$info;
		}	
		.input-group-text{
			background-color:transparent;
			border-color:$info;
			color:$info;
		}
	}
	.input-success-o{
		.form-control{
			border-color:$success;
		}	
		.input-group-text{
			background-color:transparent;
			border-color:$success;
			color:$success;
		}
	}
	.input-warning-o{
		.form-control{
			border-color:$warning;
		}	
		.input-group-text{
			background-color:transparent;
			border-color:$warning;
			color:$warning;
		}
	}
}

// Input Size/
// .form-control{
    // min-height: 40px;
// }
// .form-control-sm{
//     min-height: 36px;
// }
// .form-control-lg{
//     min-height: 52px;
// }



// Input Group
.input-group-text{
    background: var(--light);
    border: 0.0625rem solid var(--border);
    min-width: 56px;
    display: flex;
    justify-content: center;
	padding: 0.532rem 0.75rem;
	color: var(--text-gray);
    i{
        font-size: 16px;
		color: var(--text);
    }
}
.custom-file-label{
    height: 40px;
	padding: 0.5rem 0.75rem;
}
.input-group-prepend .btn,
.input-group-append .btn {
    z-index: 0;
}
.custom-select{
    background: none;
    // height: 45px;
    border-color: $border;
    color: $dark;
    // border-radius: 0px!important;
    &:focus{
        box-shadow: none;
        border-color: var(--primary);
        color: var(--primary);
    }
}
.custom-file-label{
    // height: 45px;
    // border-radius: 0px!important;
    &:after{
        background: #656C73;
        border: 1px solid $border;
        color: $white;
		@at-root [data-theme-version="dark"] & {
			background: $d-border;
			border-color: $d-border;
			color: $body-color;
		}
    }
}

.custom_file_input {
    .input-group-prepend, 
    .input-group-append {
        // height: 45px;
    }

    .custom-file-label::after {
        height: 100%;
        // line-height: 2.4;
    }
}


.form-control:disabled,
 .form-control[readonly] {
    background: var(--border);
    opacity: 1;
}
.form-group{
	margin-bottom:1rem;
}

.custom-file-label{
    background: var(--card);
    border-color: $border;
	@at-root [data-theme-version="dark"] & {
        background: $d-bg;
		border-color: $d-border;
    }
}

.input-group > .form-control-plaintext, .input-group > .form-select, .input-group > .form-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.form-file{
	.form-control{
		margin: 0;
		border-radius: 0;
		height: auto;
	} 
}

.input-group-sm{
	.form-control{
		height:2.5rem;
	}
}
.input-group-lg{
	.form-control{
		height:3.5rem;
	}
}

.validate-username{
	.input-group-text + .form-control{
		border-radius:0 $radius $radius 0!important;
	}
}
.validate-password{
	.input-group-text + .form-control + .input-group-text{
		border-radius:0 $radius $radius 0!important;
	}
}

.input-hasicon{
	position: relative;
	.icon{
		position: absolute;
		height: 100%;
		#{$dlab-pos-right} : 0;
		top: 0;
		width: 2rem;
		display: flex;
		align-items: center;
		pointer-events: none;
	}
}

.bootstrap-select.show-tick .dropdown-menu{
	.selected span.check-mark{
		#{$dlab-pos-right}: 15px;
		#{$dlab-pos-left} : auto;
	}
	li a span.text{
		margin-#{$dlab-pos-right}: 34px;
	}
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating){
	// border-top-#{$dlab-pos-right}-radius: 0 !important;
	// border-bottom-#{$dlab-pos-right}-radius: 0 !important;
	border-top-#{$dlab-pos-left}-radius: $radius;
	border-bottom-#{$dlab-pos-left}-radius: $radius;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-#{$dlab-pos-left}: calc(var(--bs-border-width) * -1);
    border-top-#{$dlab-pos-left}-radius: 0;
    border-bottom-#{$dlab-pos-left}-radius: 0;
	// border-top-#{$dlab-pos-right}-radius: $radius;
    // border-bottom-#{$dlab-pos-right}-radius: $radius;
}
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3){
	border-top-#{$dlab-pos-right}-radius: 0;
    border-bottom-#{$dlab-pos-right}-radius: 0;
	border-top-#{$dlab-pos-left}-radius: $radius;
    border-bottom-#{$dlab-pos-left}-radius: $radius;
}
.input-group button.dropdown-toggle.dropdown-toggle-split:nth-child(2){
	border-radius: 0 !important;
}