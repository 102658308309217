.dropdown-toggle:focus {
    box-shadow: none!important;
  }

  .dropdown-outline{
      border: .1rem solid var(--primary);
  }

.dropdown-menu{
	font-size: inherit;
    border: 0;
    z-index: 3;
    overflow: hidden;
    border-radius: 0.625rem;
    box-shadow: 0 0 3.125rem 0 rgba(82, 63, 105, 0.15);
    margin-top: 0;
	background: var(--card);
		
	.dropdown-item{
		// font-size: 16px;
		font-size: .9375rem;
		color:var(--text-gray);
		padding: 0.5rem 1.75rem;
		text-align: #{$dlab-pos-left};
		&:focus,
		&:active,
		&.active{
			color: var(--primary) !important;
			background: var(--rgba-primary-1);
		}
		&:hover{
			background-color: var(--light);
			color: var(--text-dark);
		}
	}
	&.show{
		#{$dlab-pos-right}: 0;
		#{$dlab-pos-left}: auto;
	}
}

////////////////////
// Custom Dropdown
///////////////////
.dropdown-toggle-split {
    padding: 0 10px;
    opacity: 0.85;
	&:after{
		margin-#{$dlab-pos-left}:0 !important;
	}
	&:active,
	&:focus,
	&:hover{
		opacity:1;
	}
}
.custom-dropdown{
    display: inline-block;
    margin-bottom: 1rem;

    .dropdown-menu{
        border: 0px;
        min-width: 160px;
        // padding: 1rem;
		
    }
}
.card-action{
    .custom-dropdown{
        // padding: 15px;
        margin: 0px;
        background: var(--rgba-primary-1);
        &.show,&:focus,&:hover{
            background: var(--primary);
            color: var(--card);
        }

        i{
            display: inline-block;
            padding-top: 9px;
        }
    }
}

.dropdown{
	.dropdown-dots{
		position: relative;
		height: 5px;
		width: 5px;
		background: rgba($dark, 0.4);
		border-radius: 5px;
		display: block;	
		&:after,
		&:before{
			content: "";
			height: 5px;
			width: 5px;
			background: rgba($dark, 0.4);
			position: absolute;
			border-radius: 5px;
		}
		&:after{
			#{$dlab-pos-right} : -8px;
		}
		&:before{
			#{$dlab-pos-left} : -8px;			
		}
		
		&.text-white{
			background: rgba($white, 0.7);
			&:after,
			&:before{
				background: rgba($white, 0.7);				
			}
		}
	}
}

.bootstrap-select .dropdown-menu .dropdown-item {
	padding: var(--dz-list-space-y) var(--dz-list-space-x);
	font-size: .9rem;
}