//nav header styles
[direction="rtl"] {
    .hamburger.is-active .line:nth-child(1){
        -webkit-transform: translateY(4px) translateX(-12px) rotate(-45deg);
        transform: translateY(4px) translateX(-12px) rotate(-45deg);
    }
    .hamburger.is-active .line:nth-child(3) {
        -webkit-transform: translateY(-4px) translateX(-12px) rotate(45deg);
        transform: translateY(-4px) translateX(-12px) rotate(45deg);
    }
}