.popover{
    border: 2px solid var(--secondary);
    min-width: 210px;
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
    &-header{
        background: var(--secondary);
        color: $white;
        font-weight: 300;
        &::before{
            border-bottom: 0 !important;
        }
    }
    &-body{
        font-size: 0.75rem;
    }
}

.bootstrap-popover-wrapper{
    .bootstrap-popover:not(:last-child){
        margin-#{$dlab-pos-right}: 8px;
    }

    .bootstrap-popover{
        margin-bottom: .5rem;

        button:hover,button:focus{
            background: var(--primary);
            color: $white;
            box-shadow: none;
        }
    }
}

// .tooltip {
//     font-size: 15px;
// }


.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 0px;
    border-top-color: var(--secondary);
}

.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    #{$dlab-pos-right} : 0px;
    border-left-color: var(--secondary);
}

.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    #{$dlab-pos-left} : 0px;
    border-right-color: var(--secondary);
}

.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 0px;
    border-bottom-color: var(--secondary);
}

.note-popover{
    display: none;
}
.clockpicker-popover{
    border-color: var(--border);
    .popover-title{
        background: var(--card);
        color: var(--text-gray);
        border-bottom: 1px solid var(--border);
    }
    .popover-content{
        background: var(--card);
    }
    .clockpicker-plate{
        background: var(--card);
        border-color: var(--border);
        .clockpicker-tick{
            color: var(--text-dark);
        }
    }
}
.clockpicker-button{
    background-color: var(--light);
    color: var(--text-dark);
    border-radius: 0;
}

.toltip-popover{
	.tooltip-inner{
		background: #ffffff;    
		padding: 0;
		border-top-left-radius: calc(0.3rem - 1px);
		border-top-right-radius: calc(0.3rem - 1px);
		border: 0.125rem solid $secondary;        ;
		strong{
			color: #777777;
            // padding: 10px;
		}
	}
}
.popover-header{
    background: $secondary;
    border-top-left-radius: 0.1rem;
    border-top-right-radius: 0.1rem;
    padding: 10px;
}
