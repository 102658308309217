/*
===================================
    list view
===================================*/

.new-arrival-content {
    .item {
        font-size: 12px;
        color: $dark;
    }
    h4 {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .price {
        font-weight: 700;
        color: var(--primary);
        font-size: 22px;
        margin-bottom: 20px;
    }
    p {
        font-size: 1rem;
        color: $d-ctl;
        margin-bottom: 6px;
        line-height: 24px;
    }
    .text-content {
        margin-top: 18px;
    }
    .discount {
        font-size: 1rem;
        margin-right: 8px;
        opacity: 0.8;
        color: var(--primary);
    }
}

.success-icon {
    color: $success;
    font-size: 16px;
}

.comment-review {
    margin-bottom: 0.9375rem;
    display: table;
    width: 100%;
    .client-review {
        color: $d-ctl;
        padding-#{$dlab-pos-right}: 20px;
        text-decoration: underline !important;
        font-size: 1rem;
    }
    .span {
        color: $d-ctl;
        font-size: 1rem;
    }
}

.star-rating li {
    display: inline-block;
    i {
        color: gold;
    }
}

.slide-item-list{
    border: 0;
}