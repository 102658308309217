//demo styles
table#example{
    padding: 0rem 0 2rem 0;
	@include respond ('phone'){
		padding: 0rem 0 .5rem 0;
	}
}
table.dataTable{
	color:var(--text-gray);
	border-collapse: collapse;
	border-radius:$radius;
	.form-check{
		display: inline-block;
	}
	& > thead > tr > th{
		padding: 1rem 0.9375rem;
		padding-#{$dlab-pos-right}: 26px;
		padding-#{$dlab-pos-left}: 0.9375rem !important;
		text-align: #{$dlab-pos-left};
	}
	tfoot{
		th{
			text-align: #{$dlab-pos-left};
		}
	}
	tbody{
		tr{
			&:last-child{
				td{
					border-bottom: 0;
				}
			}
		}
	}
}
#example2_wrapper {
    .dataTables_scrollBody {
        max-height: 33.25rem !important;
    }
}

#employees, #custommers {
    padding: .5rem 0 1rem 0;
}
//////////
.dataTables_wrapper .dataTables_paginate{
    padding:10px 0;
	@include respond('laptop') {
		padding:5px 0;
	}
}
table.dataTable > thead > tr > th, table.dataTable > thead > tr > td{
   	padding: 1rem 0.9375rem;
    border-bottom: 1px solid var(--border);
}

//border no
table.border-no.order-table tbody tr{
	&:hover{
		box-shadow:none;
		background:var(--rgba-primary-5)!important;
	}
	td{
		border:0;
	}
}

table.dataTable tfoot th,
table.dataTable tfoot td{
    border-top: 0;
}

table.dataTable tbody tr, table.dataTable tbody td{
    background: transparent !important;
}

table.dataTable thead th{
    color: var(--text-dark);
	font-size:1rem;
	white-space: nowrap;
    font-weight: 600;
}

table.dataTable tbody td{
	font-size: 0.9375rem;
    padding: 0.65rem 0.9375rem;
	border-bottom:1px solid;
}

table.dataTable tr.selected{
    color: var(--primary);
}

table.dataTable tfoot th{
    color: var(--text-dark);
	font-size:1rem;
	padding: 15px 15px;			
    font-weight: 600;
}
.dataTables_wrapper {
    input[type="search"], input[type="text"], select {
        border: 1px solid #e2e2e2;
        padding: .3rem 0.5rem;
        color: rgba(113, 93, 93, 1);
        border-radius: 5px;
		height: 2.6rem;		 
        @at-root [data-theme-version="dark"] & {
            background: $d-bg;
            border-color: $d-border;
            color: $white;
        }
    }
	.dataTables_length{
		margin-bottom: 15px;
		float:left;	 
		.bootstrap-select{
			width: 100px!important;
			margin: 0 5px;
		}
		@include respond ('phone'){
			float:unset;
			text-align:left!important;
		}		  
	}
}
.dataTables_wrapper{
	.bootstrap-select .btn{
		padding: .5rem 1rem;
	}
}
table.dataTable.no-footer{
    border-bottom: 0;
}
.min-w850{
	min-width:850px;
}

.rounded-lg{
	min-width:30px;
}
.dataTables_scroll{
    padding: 1rem 0;

    &Foot{
        padding-top: 1rem;
    }
}
.dataTables_wrapper .dataTables_length, 
.dataTables_wrapper .dataTables_filter {
    @include media-breakpoint-down(md) {
        text-align: #{$dlab-pos-left};
    }
	 @include respond ('phone'){
		label{
			display:flex;
			align-items :center;
			input{
				width : 100%!important;
			}
		}
	}
}
.dataTablesCard{
	background-color: #fff;
	box-shadow: 0px 12px 23px 0px rgba(62, 73, 84, 0.04);
	border-radius: 0;
	
	tbody tr:hover{
		background:$white!important;
		box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
		td{
			background:transparent!important;
		}
	}
}
table.dataTable.display tbody tr:hover{
	box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
	td{
		background:transparent!important;
	}
}
.dataTables_wrapper .dataTables_info {
    padding:10px 0;
	float:left;
	margin-top: 15px;		
	@include respond('laptop') {
		padding:8px 0;
		margin-top:10px;
		padding-#{$dlab-pos-right} : 15px;		 
	}
	@include respond ('phone'){
		float:unset;
		margin-top:0;
		padding-#{$dlab-pos-right} : 0px;
	}
}
//table.dataTable.display.white-border td{
//	border-color:$white;			  
//}
table.dataTable.row-border tbody th, 
table.dataTable.row-border tbody td, 
table.dataTable.display tbody th, 
table.dataTable.display tbody td {
    border-color: var(--border);
    @at-root [data-theme-version="dark"] & {
        border-color: $d-border;
    }
}
table.dataTable thead .sorting{
	background-position: center right 15px;

}

.dataTables_wrapper .dataTables_paginate{
	//border-radius:$radius;
	span{
		border-radius:$radius;
		display: inline-block;
		margin: 0 0;
			
		a{
			color:$white;
			background: transparent !important;
		}
		//.paginate_button{
		//	border-right : 0!important;
		//	&:first-child{
		//		border-left : 0!important;
		//	}
		//}				   
	}
	.paginate_button{
		border: 0 !important;
		height: 35px;
		line-height: 35px;
		width: 35px;
		padding: 0;
		text-align:center;
		background-color: var(--rgba-primary-1) !important;
		border-radius: $radius;
		color:var(--primary)!important;
		font-size:16px;
		margin-#{$dlab-pos-left}:5px;
		@include respond('laptop') {
			font-size:1rem;
		}
		&:active{
			box-shadow:none;
		}
		&:hover,
		&.current:hover,
		&.current{
			color:$white !important;
			background:var(--primary)!important;
		}
		
		&.previous,
		&.next{
			background:var(--primary) !important;
			color:$white !important;
			margin-#{$dlab-pos-left}: 5px;
			font-size: 1rem;
			margin-top: 5px;
			margin-bottom: 5px;
			border: 0 !important;
			height: 35px;
			width: auto;
			padding: 0 15px;
			line-height: 35px;
			&:hover{
				background:var(--rgba-primary-1)!important;
				color:var(--primary)!important;
			}
			&.disabled{
				background-color: rgba($dusty-gray,0.3) !important;
				color: var(--text-dark) !important
			}
		}
	}
}
table.dataTable > tbody > tr.child ul.dtr-details > li{
	padding: 12px 15px;									   
}

table.dataTable > tbody > tr.child span.dtr-title{
	color: #000;
	font-weight: 600;
}
table.dataTable > tbody > tr.child ul.dtr-details{
	width:100%;
			  
}
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control::before, table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control::before{
	background-color:var(--primary);
	box-shadow:none;
	border:0;
}

.bg-none{
	&:after,
	&:before{
		content:none!important;
	}
}

.table .thead-white th {
    background-color: #fff;
    color: #000;
}
table.dataTable thead>tr>th.sorting:before{
	content:none;
}

table.dataTable thead>tr>th.sorting:after,
table.dataTable thead>tr>th.sorting_desc:after,
 table.dataTable thead>tr>th.sorting_asc:after{
	content:"";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    background-image: none;
    margin-#{$dlab-pos-left}: 5px;
    font-size: calc(100% - 2px);
    opacity: 0.5;
	top:50%;
	transform:translateY(-50%);
	#{$dlab-pos-right} : 10px;
	#{$dlab-pos-left} : auto;
}
table.dataTable thead>tr>th.sorting:after {
    content:"\f0dc";
}
 table.dataTable thead>tr>th.sorting_asc:after {
    content:"\f0de";
	opacity: 0.5;
}
table.dataTable thead>tr>th.sorting_desc:after {
    content:"\f0dd";
	opacity: 0.5;
}
table.dataTable thead .sorting {
    background-image: none;
}

table.dataTable thead .sorting_asc {
    background-image: none;
}

table.dataTable thead .sorting_desc {
    background-image: none;
}
table.dataTable thead .sorting_asc_disabled {
    background-image: none;
}

table.dataTable thead .sorting_desc_disabled {
    background-image: none;
}

.table.dataTable.row-border>tbody>tr>th, 
table.dataTable.row-border>tbody>tr>td, 
table.dataTable.display>tbody>tr>th, 
table.dataTable.display>tbody>tr>td {
	box-shadow:none!important;
}

table.dataTable thead > tr > th.sorting::before, table.dataTable thead > tr > th.sorting::after, table.dataTable thead > tr > th.sorting_asc::before, table.dataTable thead > tr > th.sorting_asc::after, table.dataTable thead > tr > th.sorting_desc::before, table.dataTable thead > tr > th.sorting_desc::after, table.dataTable thead > tr > th.sorting_asc_disabled::before, table.dataTable thead > tr > th.sorting_asc_disabled::after, table.dataTable thead > tr > th.sorting_desc_disabled::before, table.dataTable thead > tr > th.sorting_desc_disabled::after, table.dataTable thead > tr > td.sorting::before, table.dataTable thead > tr > td.sorting::after, table.dataTable thead > tr > td.sorting_asc::before, table.dataTable thead > tr > td.sorting_asc::after, table.dataTable thead > tr > td.sorting_desc::before, table.dataTable thead > tr > td.sorting_desc::after, table.dataTable thead > tr > td.sorting_asc_disabled::before, table.dataTable thead > tr > td.sorting_asc_disabled::after, table.dataTable thead > tr > td.sorting_desc_disabled::before, table.dataTable thead > tr > td.sorting_desc_disabled::after {
	position: relative;
	display: inline-block;
	#{$dlab-pos-right} : 0;
	line-height: 0;
}