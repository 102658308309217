.asColorPicker-dropdown {
    max-width: 26rem;
    background-color: var(--card);
}
.asColorPicker-hex{
    background-color: var(--light);
    color: var(--text-dark);
}
.asColorPicker-trigger {
    border  : 0 none;
    height  : 100%;
    position: absolute;
    #{$dlab-pos-right}: -8px;
    #{$dlab-pos-left}:auto;
    top     : 0;
    width   : 2.1875rem;
    overflow: hidden;
    border-top-#{$dlab-pos-right}-radius: 0.5rem !important;
    border-bottom-#{$dlab-pos-right}-radius: 0.5rem !important;
}

.asColorPicker-gradient-control {
    float: #{$dlab-pos-right};
}

.asColorPicker-clear {
    display: none;
    position: absolute;
    #{$dlab-pos-right}: 1rem;
    #{$dlab-pos-left}:auto;
    text-decoration: none;
    top: .5rem;
}

.asColorPicker-buttons{
    float: #{$dlab-pos-right};
}

.daterangepicker {
    td.active {
        background-color: var(--primary);
        &:hover {
            background-color: var(--primary);
        }
    }
    button.applyBtn {
        background-color: var(--primary);
        border-color: var(--primary);
    }
}

.datepicker {
    &.datepicker-dropdown {
        background: $l-bg;
        border-radius: 1px;
        border: 1px solid $gallery;
        td.day, th.next, th.prev {
            height: 35px;
            width: 35px !important;
            padding: 0;
            text-align: center;
            font-weight: 300;
        }
        th.datepicker-switch, th.next, th.prev {
            font-weight: 300;
            color: var(--text-dark);
            &:hover{
                background: var(--light);
            }
        }
        th.dow {
            color: var(--text-dark); 
        }
    }
    table {
        tr td.selected, tr td.active.active {
            @extend %gradient-9;
            box-shadow: 0px 0px 30px 5px rgba(243,30,122,0.3);
            border: 0;
        }
        tr {
            td.today {
                @extend %gradient-9;
                box-shadow: 0px 0px 30px 5px rgba(243,30,122,0.3);
                color     : #ffffff;
                &:hover {
                    @extend %gradient-9;
                    box-shadow: 0px 0px 30px 5px rgba(243,30,122,0.3);
                    color     : #ffffff;
                }
            }
            td.today.disabled {
                @extend %gradient-9;
                box-shadow: 0px 0px 30px 5px rgba(243,30,122,0.3);
                color     : #ffffff;

                &:hover {
                    @extend %gradient-9;
                    box-shadow: 0px 0px 30px 5px rgba(243,30,122,0.3);
                    color     : #ffffff;
                }
            }
        }
    }
}

.picker {
    &__select--month, &__select--year {
        height: 2.5em;
    }
    &__input {
        background-color: transparent !important;
    }
}

 .daterangepicker .calendar-table td{
	width:33px;
	height:33px;
	//border-radius:0.5rem;
	color:#B5B5C3;
}
.daterangepicker td.in-range{
	//background-color:transparent;
	color:#5E6278 !important;
}
.daterangepicker td.active{
	background-color:var(--primary);
	color:$white!important;
	&:hover{
		color:$white!important;
	}
}
button.cancelBtn.btn.btn-sm.btn-inverse{
	background-color:$danger;
    color: $white;
}
.clockpicker-popover{
	&.popover{
		position:absolute;		
		.popover-title{
			padding:10px 0;
		}	
	}
}
.pull-right{
	float:#{$dlab-pos-right};
}
.form-file{
    overflow: hidden;
}
.form-file-input.form-control{
	margin:0!important;
	height:auto!important;
}

.dtp .dtp-close{
    top: 1rem;
}
.dtp > .dtp-content{
    border-radius: $radius;
    background-color: var(--card);
    max-height: 570px;
    & > .dtp-date-view{
        & > header.dtp-header{
            background-color: var(--primary-dark);
            padding: .75rem 1rem;
            border-top-#{$dlab-pos-left}-radius: $radius;
            border-top-#{$dlab-pos-right}-radius: $radius;
        }
    } 
    .dtp-buttons{
        .btn{
            font-size: .85rem;
            padding: .35rem 1rem;
        }
        .dtp-btn-ok{
            background: var(--rgba-primary-1);
            color: var(--primary);
        }
        .dtp-btn-cancel{
            &:hover{
                color: $danger;
            }
        }
    }
}
.dtp{
    direction: ltr;
    div.dtp-date,div.dtp-time{
        background-color: var(--primary);
    }
    div.dtp-actual-year{
        color: rgba($white,.8);
        font-size: 1.2rem;
    }
    .p10 > a{
        color: $white;
    }
    div.dtp-actual-num{
        font-weight: 600;
    }
    div.dtp-date > div{
        padding: 5px 0;
    }
    table.dtp-picker-days tr > td{
        font-weight: 500;
        font-size: .75rem;
        &:last-child{
            text-align: center;
        }
    }
    div.dtp-picker-month{
        padding-bottom: 10px!important;
    }
    table.dtp-picker-days tr > td > a, .dtp .dtp-picker-time > a{
        color: var(--text-dark);
        display: inline-block;
        height: 1.8rem;
        width: 1.8rem;
    }
    table.dtp-picker-days tr > th{
        color: var(--text-gray);
        font-weight: 600;
        font-size: .875rem;
        &:last-child{
            text-align: center;
        }
    }
    table.dtp-picker-days tr > td > a.selected{
        background-color: var(--primary);
    }
    #dtp-svg-clock{
        .svg-clock{
            circle:first-child{
                fill: var(--body-bg);
                stroke: var(--border);
            }
            text.dtp-select-hour-text{
                fill: var(--text-dark);
            }
        }
    }
}

.daterangepicker{
    font-family: poppins !important;
    select{
        background: var(--card) !important;
        color: var(--text-dark) !important;
        border-color: var(--border) !important;
    }
}


.picker-data{
	.color-time-picker{
		.react-time-picker{
			width:100%;
		}
		.react-time-picker__wrapper{
			border:0;
			color:#7e7e7e;
			.react-time-picker__clear-button{
				display:none;
			}
			.react-time-picker__inputGroup{
				border:1px solid #b7b7b7;
				border-width:0 0 1px 0;
				padding: .35rem 0rem
			}
			.react-time-picker__inputGroup__input{
				color:#7e7e7e;
			}
			.react-time-picker__inputGroup__amPm{
				color:#7e7e7e;
				appearance: none;
			}
			.react-time-picker__clock-button{
				background:$primary;
				color: #b7b7b7;
				padding: .532rem .75rem;
				border-radius: 0 0.75rem 0.75rem 0;
				min-width: 3.125rem;
				text-align: center;
				line-height: 1;
				svg{
					stroke: #fff;
					display:inline-block;
				}
			} 
		}
		.react-clock__face{
			background: #ededed;
			padding: 0.625rem 0.625rem;	
			border: 0.625rem solid #ededed;
		}
		.react-clock__hand__body{
			background-color: $primary;
		}
		.react-time-picker__clock{
			box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12);
			border-radius:4px;
			border: 0 !important;
		}
		&.style-1{
			.react-time-picker__wrapper{
				.react-time-picker__clock-button{
					display: none;
					
				} 
			}
			
		}
		
	}
}
.color-gradian-tixia{
    .gpw{
        padding: 0;    
    }
    .gpw .trigger .inner{
        height: 1.5rem;
        width: 6.25rem;
    }

}