.header-right {
    height: 100%;
    .nav-item {
        height: 100%;
        display: flex;
        align-items: center;
        .nav-link {
            #icon{
                &-light,&-dark{
                    color: var(--primary);
                    font-size: 1.2rem;
                }
            }
            svg{
                width:1.25rem;
                height:1.25rem;
                path{
                    fill : var(--primary);
                }
            }
            #icon-user{
                path{
                    fill: transparent;
                }
            }
            
        }
    }
    &>li {
        &:not(:first-child) {
            padding-#{$dlab-pos-left}: 1rem;
        }
    }
    .notification_dropdown {
        @include respond('phone-land') {
            position: static;
        }
        .nav-link {
            position: relative;
            color: var(--text);
            i {
                font-size: 18px;
                font-weight: 700;
            }
            .badge {
                position: absolute;
                font-size: 0.625rem;
                border-radius: 50%;
                #{$dlab-pos-right} :  0rem;
                top: 0rem;
                font-weight: normal;
                height: 1.1rem;
                width: 1.1rem;
                line-height: 1.6;
                text-align: center;
                padding: 0;
                font-size: 0.6rem;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
                min-width: 1.1rem;
                min-height: 1.1rem;
            }
        }
        .dropdown-item {
            &:focus,
            &:active {
                a {
                    color: $white;
                }
            }
            a {
                color: $dark;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    .dropdown-menu {
        border-width:0;
        box-shadow: 0 0 37px rgba(8,21,66,0.05);
    }
    .header-profile {
        &>a {
            i {
                font-weight: 700;
            }
        }
        .dropdown-menu {
            padding: 5px 0;
            min-width: 12.5rem;
            a {
                &:hover,
                &:focus,
                &.active {
                    color: var(--primary);
                }
            }
        }
        img {
            width: 30px;
            height: 30px;
            border-radius: 50px;
        }
        .dropdown-toggle {
            i {
                font-size: 1.25rem;
            }
            span {
                @include respond('phone') {
                    display: none;
                }
            }
        }
        .profile_title {
            background: var(--primary);
            color: $white;
            padding: 10px 20px;
            h5 {
                color: $white;
                margin-bottom: 3px;
            }
        }
        .dropdown-item {
            padding: 8px 24px;
        }
    }
}

.notification_dropdown {
    .dropdown-menu-end {
        min-width: 19.375rem;
        padding: 0rem 0 1rem;
        top: 100%;
        text-align: #{$dlab-pos-left};
    }
    .dropdown-menu-right {
        min-width: 275px;
        padding: 0rem 0 1rem;
        top: 100%;
        .notification_title {
            background: var(--primary);
            color: $white;
            padding: 10px 20px;
            h5 {
                color: $white;
                margin-bottom: 3px;
            }
        }
        .media {
            border-bottom: 1px solid var(--border);
            padding: 1rem;
            cursor: pointer;
			
            &>span {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                &.success {
                    background: #DEF2DB;
                    color: #2F9B1B;
                }
                &.primary {
                    background: #D3E7FE;
                    color: #176AD2;
                }
                &.danger {
                    background: #FFE0E0;
                    color: #E24747;
                }
            }
            .notify-time {
                margin-#{$dlab-pos-right}: 10px!important;
                color: var(--text-dark);
            }
            p {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 200px;
                margin-bottom: 0;
                color: var(--text);
				@include respond('phone') {
				    max-width: 100px;
				}
            }
        }
        .all-notification {
            display: block;
            padding: 15px 30px 0;
            text-align: center;
            color: var(--primary);
            i {
                margin-#{$dlab-pos-left}: 10px;
            }
        }
    }
}
.dlab-theme-mode{
	#icon-light{
		display:none;
	}
	&.active{
		#icon-light{
			display:inline-block;
		}
		#icon-dark{
			display:none;
		}
	} 
}