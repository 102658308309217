[data-container="wide-boxed"] {
    @media (min-width: 992px) {
        #main-wrapper {
            max-width: 82.5rem;
            margin: 0 auto;
            box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.1);
        }
    }
}

[data-container="wide-boxed"][data-sidebar-style="full"] {
    @media (min-width: 992px) {
        .header {
            width: 100%;
        }
        .menu-toggle {
            .header {
                width: 100%;
            }
        }
        @at-root [data-header-position="fixed"]#{&} {
            .header {
                max-width: 82.5rem;
            }
            .menu-toggle {
                .header {
                    max-width: 82.5rem;
                }
            }
        }
    }
}

[data-container="wide-boxed"][data-sidebar-style="mini"] {
    @media (min-width: 992px) {
        .header {
            width: 100%;
        }
        @at-root [data-header-position="fixed"]#{&} {
            .header {
                max-width: 82.5rem;
            }
        }
        @at-root [data-header-position="fixed"][data-layout="horizontal"]#{&} {
            .header {
                max-width: 82.5rem;
            }
        }
    }
}

[data-container="wide-boxed"][data-sidebar-style="compact"] {
    @media (min-width: 992px) {
        .header {
            width: 100%;
        }
        @at-root [data-header-position="fixed"]#{&} {
            .header {
                max-width: 82.5rem;
            }
        }
    }
}

[data-container="wide-boxed"][data-sidebar-style="overlay"] {
    @media (min-width: 992px) {
        .header {
            width: 100%;
        }
        @at-root [data-header-position="fixed"]#{&} {
            .header {
                max-width: 82.5rem;
            }
        }
    }
}

[data-layout="horizontal"][data-container="wide-boxed"] {
    .header {
        width: 100%;
        max-width: 82.5rem;
    }
    @at-root [data-sidebar-style="full"][data-header-position="fixed"]#{&} {
        .header {
            max-width: 82.5rem;
        }
    }
    @at-root [data-sidebar-style="mini"]#{&} {
        .header {
            width: 100%;
        }
    }
}

[data-layout="horizontal"][data-container="wide-boxed"][data-sidebar-position="fixed"] {
    .dlabnav.fixed {
        max-width: 82.5rem;
        transition: none;
    }
}

[data-layout="horizontal"][data-container="wide-boxed"]{
    .dlabnav {
        max-width: 82.5rem;
    }
}