#revenue-chart {
    height: 27.7rem;
}

#duration-value-axis {
    height: 27.6rem;
    width: 100%;
}

#combined-bullet { 
    height: 28.125rem;
    width: 100%;
}

#zoomable-chart {
    height: 28.125rem;
    width: 100%;
}

#chartMap {
    height: 28.125rem;
    width: 100%;
}

#professional-candlesticks {
    width: 100%;
    height: 28.125rem;
}

#comparing-stock-indices {
    width: 100%;
    height: 28.125rem;
}

#multiple-panel-data {
    width: 100%;
    height: 28.125rem;
}

#depth-chart {
    width: 100%;
    height: 28.125rem;
}

.amcharts-export-menu {
    display: none;
}

.amcharts-data-set-selector-div {
    position: absolute;
    #{$dlab-pos-left} : 0;
    #{$dlab-pos-right} : 0;
    text-align: center;
    width: 16.875rem;
    margin: 0 auto;
    select {
        border: 0;
        margin-#{$dlab-pos-left}: 0.625rem;
        background: #ddd;
        color: $black;
    }
}

.amChartsInputField {
    border: 0;
    background: var(--primary);
    color: $white;
    padding: 0.3125rem 0.9375rem;
    margin: 0 0.9375rem;
}

.amcharts-data-set-select {
    border: 0;
    background: #ddd;
    color: $black;
}

.amcharts-period-input,
.amcharts-period-input-selected {
    border: 0;
    margin-#{$dlab-pos-left}: 0.625rem;
    background: var(--primary);
    color: $white;
    padding: 0.3125rem 0.9375rem;
}

/////////////////////
// CSS Animation
///////////////////
.amcharts-graph-g2 .amcharts-graph-stroke {
    stroke-dasharray: 3px 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    -webkit-animation: am-moving-dashes 1s linear infinite;
    animation: am-moving-dashes 1s linear infinite;
}

@-webkit-keyframes am-moving-dashes {
    100% {
        stroke-dashoffset: -1.9375rem;
    }
}

@keyframes am-moving-dashes {
    100% {
        stroke-dashoffset: -1.9375rem;
    }
}

.lastBullet {
    -webkit-animation: am-pulsating 1s ease-out infinite;
    animation: am-pulsating 1s ease-out infinite;
}

@-webkit-keyframes am-pulsating {
    0% {
        stroke-opacity: 1;
        stroke-width: 0px;
    }
    100% {
        stroke-opacity: 0;
        stroke-width: 3.125rem;
    }
}

@keyframes am-pulsating {
    0% {
        stroke-opacity: 1;
        stroke-width: 0px;
    }
    100% {
        stroke-opacity: 0;
        stroke-width: 3.125rem;
    }
}

.amcharts-graph-column-front {
    -webkit-transition: all .3s .3s ease-out;
    transition: all .3s .3s ease-out;
}

.amcharts-graph-column-front:hover {
    fill: #496375;
    stroke: #496375;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
}

@-webkit-keyframes am-draw {
    0% {
        stroke-dashoffset: 500%;
    }
    100% {
        stroke-dashoffset: 0%;
    }
}

@keyframes am-draw {
    0% {
        stroke-dashoffset: 500%;
    }
    100% {
        stroke-dashoffset: 0%;
    }
}

.amChartsPeriodSelector {
    &>fieldset>div {
        @include respond('tab-port') {
            float: none !important;
            display: block !important;
            margin-bottom: 0.625rem;
        }
    }
}