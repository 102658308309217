//customize bootstrap
@import './bs-custom';

$strong:#646c9a;
$shadow: 0px 0px 40px 0px rgba(82,63,105,0.1);

///////////////
// Solid Color
///////////////
$white: #fff;
$black: #000;
$charade: #23252F;

/////////////////////////
// Dark Version Color
/////////////////////////
$d-ctd: #ddd;
$d-ctl: #828690;
$d-border: #383c46;
$d-bg: #1d1e26;
$dark-card: #262932;
$dark_bg_lighter: #1E2A4A;

/////////////////////////
// Light Version Color
/////////////////////////
$text-dark: #312a2a;
$text-gray: #737B8B;
$text: #888888;
$border: #e6e6e6;
$body-bg: #f5f5f5;
$card: #fff;
$l-ctd: #464a53;
$l-ctl: #828690;
$l-border: #eaeaea;
$l-bg: #f2f4fa;
$heading: #333;
//$dark_bg_lighter: #1E2A4A;


/////////////////////////
// Dark Version Color
/////////////////////////
$d-ctd: #ddd;
$d-ctl: #828690;
$d-text-dark : #fff;
$d-text-gray : #b3b3b3;
$d-text : #828690;
$d-border: #3d3d4e;
$d-bg: #17171E;
$dark-card: #212130;
$dark_bg_lighter: #1E2A4A;

///////////////////
// Material Colors
///////////////////
$dusty-gray: #999999;
$gallery: #EEEEEE;
$gray: #898989;
$input-border-color: $border;

/////////////////////////
// Primary Light
/////////////////////////
$primary-light: lighten($primary, 25%);
$primary-dark: darken($primary, 25%);
$secondary-light: lighten($secondary, 50%);
$secondary-dark: darken($secondary, 35%);
$success-light: lighten($success, 45%);
$warning-light: lighten($warning, 30%);
$danger-light: lighten($danger, 38%);
$info-light: lighten($info, 37%);
$dark-light: lighten($dark, 50%);

/////////////////////////
// Opacity 
/////////////////////////
$primary-opacity: rgba($primary, 0.2);
$secondary-opacity: rgba($secondary, 0.2);
$success-opacity: rgba($success, 0.1);
$warning-opacity: rgba($warning, 0.1);
$danger-opacity: rgba($danger, 0.15);
$info-opacity: rgba($info, 0.1);
$dark-opacity: rgba($dark, 0.35);

///////////////////
// Social Colors
///////////////////
$facebook: #3b5998;
$twitter: #1da1f2;
$youtube: #FF0000;
$google-plus: #db4439;
$linkedin: #007bb6;
$instagram: #c32aa3;
$pinterest: #bd081c;
$google: #4285f4;
$snapchat: #fffc00;
$whatsapp: #25d366;
$tumblr: #35465d;
$reddit: #ff4500;
$spotify: #1ed760;
$yahoo: #430297;
$dribbble: #ea4c89;
$skype: #00aff0;
$quora: #aa2200;
$riverBed: #4C585C;
$vimeo: #1ab7ea;


///////////////////
// Misc Colors
///////////////////
$iron: #DDDFE1;
$grey: #D2D6DE;
$pale-sky: #6a707e;
$athensGray: #F0F0F2;
$sky: #2290FF;


//color for coller pallate
$color_pallate_1: $white;
$color_pallate_2: #143b64;
$color_pallate_3: $primary;
$color_pallate_4: #4527a0;
$color_pallate_5: #c62828;
$color_pallate_6: #283593;
$color_pallate_7: #1e78ff;
$color_pallate_8: #3695eb;
$color_pallate_9: #00838f;
$color_pallate_10: #ff8f16;
$color_pallate_11: #6673fd;
$color_pallate_12: #558b2f;
$color_pallate_13: #2a2a2a;
$color_pallate_14: #1367c8;
$color_pallate_15: #ed0b4c;

//utility variables
$radius: 5px; 
$radius-sm : 3px; 

$dlab-pos-right: right;
$dlab-pos-left: left;
$dlab-row : row wrap;

:root{
    --card : #{$card};
    --text-dark : #{$text-dark};
    --text-gray : #{$text-gray};
    --text : #{$text};
    --body-bg : #{$body-bg};
    --light : #{$body-bg};
    --border : #{$border};
    --bs-body-color : #{$text};
    --bs-heading-color : #{$text-dark};
    --bs-emphasis-color : #{$text-dark}; 
}


