.card {
    margin-bottom: 1.875rem;
    background-color: var(--card);
    transition: all .5s ease-in-out;
    position: relative;
    border: 0px solid transparent;
    border-radius: $radius;
    box-shadow: 0px 6px 24px 0px rgba(53,55,81,0.06);
	height: calc(100% - 30px);
	
	// z-index: 0;
	@include custommq($max: 575px) {
		margin-bottom:0.938rem;
		height: calc(100% - 0.938rem);
	}

    &-body {
        padding: 1.875rem;
		@include respond('phone') {
			padding: 1rem;
		}
    }

    &-title {
        font-size: 1.25rem !important;
        font-weight: 500;
        // line-height: 2.2rem;
        color: var(--text-dark);
        text-transform: capitalize;
        // margin-bottom: 1.5rem;
    
        &--large{
            font-size: 1.5rem;
        }
    
        &--medium{
            font-size: 1rem;
        }
    
        &--small{
            font-size: 0.875rem;
        }
    }

    &-header {
        border-color: $border;
        position: relative;
        background: transparent;
        padding:1.5rem 1.875rem 1.25rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h6{
            color: var(--text-dark);
        }
		@include respond('phone') {
			padding: 1.25rem 1rem 1.25rem;
		}
        @at-root [data-theme-version="dark"] & {
            border-color: $d-border;
        }

        .card-title{
            margin-bottom: 0px;
        }
        .subtitle{
            padding-top: 5px;
            font-size: 1rem;
			line-height: 1.5;
        }
    }

    &-footer {
        border-color: $border;
        background: transparent;
        padding: 1.25rem 1.875rem 1.25rem;
        @at-root [data-theme-version="dark"] & {
            border-color: $d-border;
        }
		@include respond('phone') {
			padding: 1rem;
		}
    }    
}